import state from '@/state'
export default {
  data: function () {
    return {
      listeners: []
    }
  },
  computed: {
    density () {
      return state.density
    },
    printer () {
      return state.getPrinter()
    },
    drawer: {
      set: (drawer) => {
        state.drawer = drawer
      },
      get: () => {
        return state.drawer
      }
    },
    companyId () {
      return state.getCurrentCompany().id
    },
    stateEvents () {
      return state.events
    },
    search: {
      set: (search) => {
        state.search = search
      },
      get: () => {
        return state.search
      }
    },
    filter: {
      set: (filter) => {
        state.filter = filter
      },
      get: () => {
        return state.filter
      }
    },
    densityPadding () {
      switch (this.density) {
        case 'greater': {
          return 'py-0'
        }
        case 'normal': {
          return 'py-1'
        }
        case 'smaller': {
          return 'py-2'
        }
      }
    }
  },
  watch: {
    stateEvents: {
      deep: true,
      handler (val) {
        let actions = this.$route.meta.addItemAction || this.$route.meta.actions
        if (!actions) return
        if (!Array.isArray(actions)) actions = [actions]
        const action = Object.keys(val).find(key => val[key])
        if (action && Object.keys(val).some(key => actions.includes(key)) && Object.prototype.hasOwnProperty.call(this, action)) {
          if (this[action]) this[action]()
          setTimeout(() => {
            state.events[action] = false
          }, 5)
        }
      }
    }
  },
  beforeDestroy () {
    if (this.listeners) {
      this.detachListeners()
    }
    if (state.search) state.search = ''
    if (state.filter) state.filter = ''
  },
  methods: {
    detachListeners () {
      this.listeners.forEach(listener => {
        listener()
        this.listeners = this.listeners.filter(l => l !== listener)
      })
    }
  }
}
